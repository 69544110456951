#mw-head {
  #actions {
    margin-top: 1rem;

    @media screen and (min-width: 600px) {
      margin-top: -2.5rem;
      margin-bottom: 2rem;
    }
  }
}
