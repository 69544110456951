#cse-search-box,
.searchbox {
  margin-left: 0 !important;
  margin-bottom: 1rem;

  input[type="text"] {
    margin-bottom: 1rem;
  }

  br {
    display: none;
  }
}
