// See also src/js/wiki/anchor.js

.mw-anchor {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  line-height: inherit !important;

  a {
    color: inherit;
    opacity: 0.5;
  }
}
