// Hide the [] around edit link. Reset font size and line height.
// See also src/js/wiki/edit-section.js

.mw-editsection {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  line-height: inherit !important;

  a {
    color: inherit;
    opacity: 0.5;
  }
}

.mw-editsection-bracket {
  display: none;
}
