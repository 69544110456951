/* Table of content */

#bodyContent #toc {
  display: none;
}

#toc-sidebar {
  padding: 4rem 0;
}

.toc {
  ul {
    li {
      a {
        display: flex;
      }
    }
  }
}

#toctitle {
  display: none;
}

.tocnumber {
  display: inline-block;
  margin-right: 0.5em;
  white-space: nowrap;
}

.toctext {
  overflow: hidden;
  word-wrap: break-word;
}
