// Deprecated
#sidebar {
	font-size: $font-size-sm;
	margin-left: -$grid-gutter-width / 2;
	margin-right: -$grid-gutter-width / 2;
	padding: $spacer 0;
	min-height: 100%;
	background: $gray-100;
	color: $gray-600;

	h4 {
		font-size: 1.2em;
		margin-top: 0;
	}

	form {
		flex: 1 1;

		input {
			display: block;
			width: 100%;
		}
	}
}

#sidebar-toggle-button {
	flex: 0 0;
	display: block;
	padding: 0.375rem;
	svg {
		display: block;
		margin: calc(0.75rem - 14px);
	}
}

@media screen and (min-width: 768px) {
	#sidebar-content {
		display: block;
	}
}
