.mw-highlight {
  background-color: transparent !important;

  .mi {
    @media (prefers-color-scheme: dark) {
      @import "../dark-mode/variables";
      color: $dark-mode-text-color;
    }
  }
}
