.catlinks {
	clear: both;
}

.mw-normal-catlinks {
	background: transparent;
	padding: 0.5em 1em;
	margin: 2em 0;
	border-left: 3px solid $green;
	clear: both;
}

.mw-normal-catlinks li {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.mw-normal-catlinks > a {
	color: inherit;
	font-weight: bold;
}
