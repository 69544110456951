// Override MediaWiki list styles
ol.nav,
ul.nav {
  margin-left: 0;
  margin-bottom: 0;
}

// Link inside nav link span (wiki text doesn't support class of internal and external link)
.nav-link {
  a {
    display: block;
    color: inherit;
    text-decoration: none;
    margin: -0.375rem -0.75rem;
    padding: 0.375rem 0.75rem;
  }

  strong.selflink {
    font-weight: inherit;
    user-select: none;
  }
}

/* hide "jump to navigation/search" links */
.mw-jump, #jump-to-nav {
  overflow: hidden;
  height: 0;
  zoom: 1;
}
