@media print {
  #main-wrap {
    > .row {
      display: block !important;

      > .col-12 {
        width: auto !important;
      }
    }
  }
}
