.knowledge {
  border: none;

  // Icon should not use max-width: 100% otherwise they are very small
  img {
    width: initial;
    height: initial;
  }

  ul {
    @include list-unstyled();
  }
}
