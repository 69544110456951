.mw-anchor {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  line-height: inherit !important;
}
.mw-anchor a {
  color: inherit;
  opacity: 0.5;
}

.catlinks {
  clear: both;
}

.mw-normal-catlinks {
  background: transparent;
  padding: 0.5em 1em;
  margin: 2em 0;
  border-left: 3px solid #73ba25;
  clear: both;
}

.mw-normal-catlinks li {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.mw-normal-catlinks > a {
  color: inherit;
  font-weight: bold;
}

#cse-search-box,
.searchbox {
  margin-left: 0 !important;
  margin-bottom: 1rem;
}
#cse-search-box input[type=text],
.searchbox input[type=text] {
  margin-bottom: 1rem;
}
#cse-search-box br,
.searchbox br {
  display: none;
}

.mw-highlight {
  background-color: transparent !important;
}
@media (prefers-color-scheme: dark) {
  .mw-highlight .mi {
    color: #adb5bd;
  }
}

body.action-submit #mw-head,
body.action-submit #siteNotice,
body.action-edit #mw-head,
body.action-edit #siteNotice {
  display: none;
}

.mw-editsection {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  line-height: inherit !important;
}
.mw-editsection a {
  color: inherit;
  opacity: 0.5;
}

.mw-editsection-bracket {
  display: none;
}

#mw-head #actions {
  margin-top: 1rem;
}
@media screen and (min-width: 600px) {
  #mw-head #actions {
    margin-top: -2.5rem;
    margin-bottom: 2rem;
  }
}

#content .tright,
#content .floatright {
  float: right;
  clear: right;
  margin-left: 1em;
}
#content .tleft,
#content .floatleft {
  float: left;
  clear: left;
  margin-right: 1em;
}
@media (max-width: 400px) {
  #content .tright,
#content .floatright,
#content .tleft,
#content .floatleft {
    float: none !important;
    clear: none !important;
    margin-left: 0;
    margin-right: 0;
  }
}
#content img {
  max-width: 100%;
  height: auto;
}
#content .thumbinner {
  max-width: 100%;
  margin-bottom: 1em;
}
#content .thumbinner a.image {
  display: block;
}
#content .thumbcaption {
  font-size: 0.9em;
  padding: 0.5em;
  background: #ffffff;
  border-bottom: 3px solid #73ba25;
}

a.new {
  color: #a55860;
}

.mw-content-ltr ol,
.mw-content-ltr ul,
.mw-content-ltr dl,
.mw-content-rtl .mw-content-ltr ol,
.mw-content-rtl .mw-content-ltr ul,
.mw-content-rtl .mw-content-ltr dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.list-group {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

ol.nav,
ul.nav {
  margin-left: 0;
  margin-bottom: 0;
}

.nav-link a {
  display: block;
  color: inherit;
  text-decoration: none;
  margin: -0.375rem -0.75rem;
  padding: 0.375rem 0.75rem;
}
.nav-link strong.selflink {
  font-weight: inherit;
  user-select: none;
}

/* hide "jump to navigation/search" links */
.mw-jump, #jump-to-nav {
  overflow: hidden;
  height: 0;
  zoom: 1;
}

@media print {
  #main-wrap > .row {
    display: block !important;
  }
  #main-wrap > .row > .col-12 {
    width: auto !important;
  }
}
#sidebar {
  font-size: 0.875rem;
  margin-left: -15px;
  margin-right: -15px;
  padding: 1rem 0;
  min-height: 100%;
  background: #f8f9fa;
  color: #6c757d;
}
#sidebar h4 {
  font-size: 1.2em;
  margin-top: 0;
}
#sidebar form {
  flex: 1 1;
}
#sidebar form input {
  display: block;
  width: 100%;
}

#sidebar-toggle-button {
  flex: 0 0;
  display: block;
  padding: 0.375rem;
}
#sidebar-toggle-button svg {
  display: block;
  margin: calc(0.75rem - 14px);
}

@media screen and (min-width: 768px) {
  #sidebar-content {
    display: block;
  }
}
/* Table of content */
#bodyContent #toc {
  display: none;
}

#toc-sidebar {
  padding: 4rem 0;
}

.toc ul li a {
  display: flex;
}

#toctitle {
  display: none;
}

.tocnumber {
  display: inline-block;
  margin-right: 0.5em;
  white-space: nowrap;
}

.toctext {
  overflow: hidden;
  word-wrap: break-word;
}

table.mw-enhanced-rc {
  table-layout: fixed;
}
table.mw-enhanced-rc .mw-collapsible-toggle {
  float: unset;
}
table.mw-enhanced-rc.mw-collapsible td:nth-child(1) {
  width: 15px;
}
table.mw-enhanced-rc.mw-collapsible td:nth-child(2) {
  width: 7em;
}
table.mw-enhanced-rc.mw-collapsible td:nth-child(3) {
  width: auto;
}
table.mw-enhanced-rc:not(.mw-collapsible) td:nth-child(1) {
  width: calc(7em + 15px);
}
table.mw-enhanced-rc:not(.mw-collapsible) td:nth-child(2) {
  width: auto;
}

.intro {
  border-bottom: 3px solid #35b9ab;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background: #f8f9fA;
  font-size: 1.2rem;
  font-style: italic;
}

.knowledge {
  border: none;
}
.knowledge img {
  width: initial;
  height: initial;
}
.knowledge ul {
  padding-left: 0;
  list-style: none;
}